import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthStore } from '@twist/core';

export const authGuard: CanActivateFn = async (route: ActivatedRouteSnapshot,
                                               state: RouterStateSnapshot) => {

  const authStore = inject(AuthStore);

  const isAuthenticated = await authStore.isAuthenticatedPromise();

  if (!isAuthenticated) {
    authStore.login(state.url);
  }

  return isAuthenticated;

};

export type AuthGuardOptions = {
  allowedRoles: string[]
}

export const authGuard2 = (options: AuthGuardOptions) => {
  const guard: CanActivateFn = async (route: ActivatedRouteSnapshot,
                                      state: RouterStateSnapshot) => {

    const authStore = inject(AuthStore);
    const router = inject(Router);

    const authenticated = await authStore.isAuthenticatedPromise();

    if (!authenticated) {
      return false;
    }
    if (options.allowedRoles.length > 0) {
      const user = authStore.user();
      console.log('ROLES, ', user);
      if (!user.roles.some(role => options.allowedRoles.includes(role))) {
        router.navigate(['/']).then(r => {

        });
        return false;
      }
    }
    return true;
  };

  return guard;
};
