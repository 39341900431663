import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { computed, inject } from '@angular/core';
import { AuthService } from './auth.service';
import { filter, tap } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { OAuthService } from 'angular-oauth2-oidc';
import { parseJwtAccesToken } from './jwt-token';

export type UserData = {
  id: string;
  email: string;
  name: string;
  roles: string[];
}

export type AuthState = {
  userData: UserData;
  idToken: string;
  accessToken: string;
  hasValidTokens: boolean;
}

export const initialAuthState: AuthState = {
  userData: {
    id: 'f',
    email: 's',
    name: 'f',
    roles: []
  },
  idToken: '',
  accessToken: '',
  hasValidTokens: false
};

export const AuthStore = signalStore(
  { 'providedIn': 'root' },
  withState<AuthState>(initialAuthState),
  withComputed(state => {
    const authService = inject(AuthService);

    const isAuthenticatedSignal = toSignal(authService.isAuthenticated$);
    const isDoneLoading = toSignal(authService.isDoneLoading$);
    const isLoading = computed(() => !isDoneLoading());

    const user = computed(() => state.userData());

    return {
      isAuthenticatedSignal,
      isLoading,
      user
    };
  }),
  withMethods(state => {

    const authService = inject(AuthService);

    const oauthService = inject(OAuthService);

    const isAuthenticatedPromise = (): Promise<boolean> => {
      return new Promise<boolean>((resolve, reject) => {
        authService.isDoneLoading$.pipe(
          filter(doneLoading => doneLoading),
          tap(() => {
            resolve(authService.hasValidToken());
          })
        ).subscribe();
      });
    };

    oauthService.events
      .pipe(filter(e => ['user_profile_loaded'].includes(e.type)))
      .subscribe(async e => {
        await tryExtractTokenDataToState();
      });

    oauthService.events
      .pipe(filter(e => ['silent_refresh_timeout'].includes(e.type)))
      .subscribe(async e => {
        await tryExtractTokenDataToState();
      });

    const tryExtractTokenDataToState = async () => {
      if (oauthService.hasValidAccessToken() && oauthService.hasValidIdToken()) {
        const idToken = oauthService.getIdToken();
        const accessToken = oauthService.getAccessToken();
        const idTokenData = parseJwtAccesToken(idToken);
        const accessTokenData = parseJwtAccesToken(accessToken);
        patchState(state, {
          accessToken: accessToken,
          idToken: idToken,
          hasValidTokens: true,
          userData: {
            id: idTokenData.sub,
            name: idTokenData.name || idTokenData.given_name || idTokenData.email || 'sdfsdfsdf',
            email: idTokenData.email,
            roles: accessTokenData.roles
          }
        });
      } else {
        patchState(state, initialAuthState);
      }
    };

    const initialize = async () => {
      await tryExtractTokenDataToState();
    };

    const login = (targetUrl?: string) => {
      authService.login(targetUrl);
    };

    const logout = () => {
      oauthService.logOut();
    };

    const refreshTokens = () => {
      authService.refresh();
    };

    return {
      initialize,
      logout,
      login,
      refreshTokens,
      isAuthenticatedPromise
    };
  }),
  withHooks({
    async onInit({ initialize }) {
      await initialize();
    }
  })
);
