import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSettings, EnvironmentSettings } from './settings';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AddApiKeyInterceptor } from './api-services/add-api-key.interceptor';
import { authConfigFactory, oAuthModuleConfigFactory, oAuthStorageFactory } from './authentication';
import { authAppInitializerFactory } from './authentication/auth-app-initializer.factory';
import { AuthService } from './authentication/auth.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    OAuthModule.forRoot()
  ],
  providers: [
    AuthService,
    //AuthGuard,
    //AuthGuardWithForcedLogin,
  ],
})
export class CoreModule {

  static forRoot(environmentSettings: EnvironmentSettings): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: APP_INITIALIZER, useFactory: authAppInitializerFactory, deps: [AuthService], multi: true },
        { provide: AppSettings, useValue: new AppSettings(environmentSettings) },
        { provide: AuthConfig, useFactory: authConfigFactory, deps: [AppSettings] },
        { provide: OAuthModuleConfig, useFactory: oAuthModuleConfigFactory, deps: [AppSettings] },
        { provide: OAuthStorage, useFactory: oAuthStorageFactory },
        { provide: HTTP_INTERCEPTORS, useClass: AddApiKeyInterceptor, multi: true }
      ]
    };
  }

}


