import { patchState, signalStore, withHooks, withMethods } from '@ngrx/signals';
import { setAllEntities, withEntities } from '@ngrx/signals/entities';
import { DtoSvgIcon, IconsBackboneService } from '@twist/backbone-api-services/infrastructure';
import { inject } from '@angular/core';

import { toObservable } from '@angular/core/rxjs-interop';
import { filter, map } from 'rxjs';
import { setError, setLoaded, setLoading, withCallState } from '../signal-store-features';

export const SvgIconsStore = signalStore(
  { providedIn: 'root' },
  withEntities<DtoSvgIcon>(),
  withCallState(),
  withMethods((state) => {

    const loaded$ = toObservable(state.loaded);

    const svgIconsService = inject(IconsBackboneService);
    const loadIcons = () => {
      patchState(state, setLoading());
      svgIconsService.getIcons(true)
        .subscribe({
          next: (data) => {
            console.log('[SvgIconsStore] - icons loaded', data.icons);
            patchState(state, setAllEntities(data.icons, { idKey: 'key' }));
            patchState(state, setLoaded());
          },
          error: (err) => {
            console.log('[SvgIconsStore] - icons err', err);
            patchState(state, {
              ...setAllEntities([]),
              ...setError(err.message),
              ...setLoaded()
            });
          }
        });
    };

    /*    const getIconByIds = rxMethod<string>(
          pipe(
            switchMap(iconKey => {
              return loaded$.pipe(
                filter(loaded => loaded == true),
                map(l => state.entityMap()[iconKey])
              );
            }))
        );*/

    const getIconById = (iconKey: string) => {

      console.log('[SvgIconsStore] - loaded - CALLED');
      return loaded$.pipe(
        filter(loaded => {
          console.log('[SvgIconsStore] - loaded', loaded);
          return loaded == true;
        }),
        map(l => {
          console.log('[SvgIconsStore] - loaded', state.entityMap()[iconKey]);
          return state.entityMap()[iconKey];
        })
      );
    };

    return {
      loadIcons,
      getIconById
    };
  }),
  withHooks({
    onInit({ loadIcons }) {
      loadIcons();
    }
  })
);
