export interface AccessTokenData {
  aud: string;
  email: string;
  exp: number;
  iss: string;
  jti: string;
  given_name: string;
  name: string;
  sub: string;
  nbf: string;
  roles: string[];
}


export function parseJwtAccesToken(token: string): AccessTokenData {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload) as AccessTokenData;
}
